import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import NFTCollections from "./components/NFTCollections";
import OfficialPartners from "./components/OfficialPartners";
import POHIntro from "./components/POHIntro";
import PTCAudit from "./components/PTCAudit";
import PTCEcosystem from "./components/PTCEcosystem";

function App() {
  return (
    <div className="bg-[url('./assets/images/Landing_area_BG_mobile_version.png')] sm:bg-[url('./assets/images/Landing_area_BG.png')] bg-contain bg-no-repeat bg-black font-fmbolyarsanspro w-full px-6 flex items-center justify-center sm:px-12 md:px-18 lg:px-24 xl:px-0">
      <div className="w-full max-w-[1280px]">
        <HeroSection />

        <POHIntro />

        <NFTCollections />

        <PTCEcosystem />

        <PTCAudit />

        <OfficialPartners />

        <Footer />
      </div>
    </div>
  );
}

export default App;
